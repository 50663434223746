.main-container {}

.card-container {}

.card {
  display: inline-block;
  background: #ffffff;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 4px 20px rgba(0, 0, 0, 0.05);
}

.card-title {
  color: #1a1d20;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  width: 100%;
  position: relative;
}

.icon {
  position: absolute;
  right: 2px;
  top: 2px;
}

.card-subtitle {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  color: #7d7d7d;
}

hr {
  border: 1px solid #7d7d7d;
}

.text-in {
  mix-blend-mode: normal;
  border: 1px solid #7d7d7d;
  border-radius: 10px;
  /* height: 40px; */
}


.wallet-card {
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-radius: 20px;
}

.meter-card {
  justify-content: center;
  box-shadow: 3px 5px 3px 4px rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-radius: 18px;
}