* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.side-bar-container {
  width: fit-content;
  position: sticky;
  top: 0;
  left: 0;
  float: left;
  z-index: 100000;
  background: #1e2727;

}

.side-bar-container *,
.side-bar-container {
  transition: width 0.5s;
}


main {
  width: 100%;
  padding: 20px;
}

.sbsidebar {
  position: relative;
  background: #1e2727;
  color: #fff;
  width: 30%;
  height: 100vh;
}



.sbtop_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.sblogo {
  font-size: 30px;
}

.sbbars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.sblink {
  display: grid;
  grid-template-columns: 16% 84%;
  color: #fff;
  padding: 10px 15px;
  padding-left: 40px;
  margin-bottom: 10px;
  gap: 30px;
  align-items: center;
}

.sblink:hover {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  /* border-right: 2px solid #7edd62; */
}

.sblink:hover .sbicon .svg path {
  fill: #7edd62;
  color: #7edd62;
}

.sbactive {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  border-right: 2px solid #7edd62;
}

.sbactive .sbicon .svg path {
  fill: #7edd62;
  color: #7edd62;
}

.sblink:active {
  background: transparent;
  color: #7edd62;
  text-decoration: none;
  border-right: 2px solid #7edd62;
}

.sbicon,
.sblink_text {
  font-size: 20px;
}

/* 
.cicon{
    width: 50px;
    height: 50px;
} */
.sbfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2px 15px;
  text-align: center;
  font-size: 6px;
  color: #8F9BBA;
  background: #1e2727;
}

@media (max-width:768px) {
  .side-bar-container {
    position: fixed;
    visibility: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.329);
  }

  .sidebar-visible {
    visibility: visible;
  }

  .side-bar-container,
  .side-bar-container * {
    transition: width 0.5s;
  }
}