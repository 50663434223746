.card {
  display: inline-block;
  background: #ffffff;
  border-radius: 20px;
  max-width: 576px;
  width: 45%;
  min-width: 250px;
  margin: 4px;
  border: 0px;
  box-shadow: 4px 6px rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
}
.card_subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  color: #7d7d7d;
}
.card_title {
  position: relative;
  color: #1a1d20;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  width: 100%;
  position: relative;
}
.disp {
  box-sizing: border-box;
  display: flex;

  width: 100%;
  padding: 5px 5px;

  mix-blend-mode: normal;
  border: 1px solid #7d7d7d;
  border-radius: 10px;
}
.dispicon:hover {
  filter: hue-rotate(90deg);
}
.dispicon {
  margin-right: 5px;
}
.dispval {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
}
.inContainer {
  position: relative;
}
.inhov {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  padding: 8px 13px;
}

.text_in {
  mix-blend-mode: normal;
  border: 1px solid #7d7d7d;
  border-radius: 10px;
  padding-left: 75px;
  height: 40px;
  width: 100%;
}
hr {
  border: 1px solid #7d7d7d;
}
.acc_btn {
  position: absolute;
  bottom: 0;
  right: 25px;
  background-color: #7edd62;
  border-radius: 10px;
  padding: 10px, 8px, 10px, 8px;
  width: 93px;
  border: 1px solid #7edd62;

  margin-bottom: 30px;
}
/* yam */
.acc_btn:hover {
  color: #06152b;
}
.swadiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.swap_text {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;

  color: #7d7d7d;
}

/* Styles for screens smaller than 600px */
@media (max-width: 600px) {
  .my-element {
    font-size: 14px;
  }
}

/* Styles for screens between 600px and 900px */
@media (min-width: 600px) and (max-width: 900px) {
  .my-element {
    font-size: 16px;
  }
}

/* Styles for screens larger than 900px */
@media (min-width: 900px) {
  .my-element {
    font-size: 18px;
  }
}
