.wyellow {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;

  padding: 3px 13px;
  gap: 10px;
  width: 219px;
  height: 31px;

  background: #ffba4d;
  border: 1.5px solid #ffba4d;
  border-radius: 20px;
}

.waddress {
  width: 75px;
  height: 16px;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;

  color: #7d7d7d;

  mix-blend-mode: normal;
}

.wgreen {
  display: flex;
  padding: 3px;
  gap: 13px;
  /* position: absolute; */
  float: right;
  align-self: center;
  right: 0;
  top: 0;
  margin-right: -12px;
  padding: 3px 13px;

  width: 123px;
  height: 31px;

  background: #ffffff;
  border: 1.5px solid #ffba4d;
  border-radius: 20px;
}
.wbalance {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #1e2727;
}
.wicon {
  width: 8px;
}
/* .ndropdown {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
}
 */
.dtoggle::after {
  display: none !important;
}
.dmen {
  background-color: #1e2727;
  border-radius: 0px 0px 0px 10px;
}

.nditem {
  display: flex;
  background-color: #1e2727;
  color: #ffffff;
  align-items: center;
}
.nditem:hover {
  display: flex;
  background-color: #1e2727;
  color: #ffba4d;
  align-items: center;
}
.nditem:hover .nsvg path {
  fill: #ffba4d;
}
