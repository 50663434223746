.yam {
  padding: 5px;
  border-bottom: 1px solid #7d7d7d;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.ttext {
  width: 139px;
  height: 20px;
  text-transform: capitalize;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  /* identical to box height, or 83% */

  color: #1e2727;
  margin-left: 10px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.nav_left {
  width: fit-content;
}

.nav_right {
  display: flex;
  width: fit-content;
  align-items: center;
}

.gear {
  margin: 10px;
  display: inline-block;
  color: #ffba4d;
  /* height: 1px; */
}

.name {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12.64px;
  line-height: 17px;

  /* Light Version/Text */

  color: #06152b;
  margin-left: 10px;
}

.settings-item {
  cursor: pointer;

}

.settings-item:hover * {
  color: #FFBA4D;
}