.select {
  border-radius: 10px;
  outline: none;
}

.select:focus {
  outline: none;
}

.select_option {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 2px;
  border-radius: 0px;
  border-bottom: 1px solid #7edd62;
}

.select_option:hover {
  background-color: #ffba4d;
  border-bottom: 0px;
}

.charge_amount {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  color: #7d7d7d;
}

.table {
  width: 100%;
}

.tcell {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 154% */
  color: #7d7d7d;
}
.ticon {
  margin-left: 5px;
}
.charge_button {
  width: 100%;
  background: #7edd62;
  border-radius: 10px;
  border: none;
  outline: none;
  outline-offset: none;
  offset: none;
}
.charge_button:hover {
  background: #7edd62;
  border-radius: 10px;
  outline: none;
  outline-offset: none;
  offset: none;
  color: #06152b;
}
